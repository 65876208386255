import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function Category(){
    const { id } = useParams();
    const [category, setCategory] = useState({});
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState({});
    
    useEffect(() => {
        fetch(`https://codehints.in/wp-json/wp/v2/categories/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setCategory(data);
        });

        fetch(`https://codehints.in/wp-json/wp/v2/posts?categories=${id}`)
        .then((response) => response.json())
        .then((data) => {
            setPosts(data);
        });
    }, [id]);

   // const customFieldValue = post.acf.imageUrl;
  
    
    return (
        <section>
            <div className="navbar navbar-expand-lg bg-primary-subtle">
                <div className="container">
                    <h1>
                        {category.name}
                    </h1>
                    <h5>{category.count}</h5>
                    {/* {customFieldValue} */}
                </div>
            </div>
            <div className="container">
                <div className="py-5">
                    {posts.map((post) => (
                        <div className="card mb-5">
                            <div clasclassNames="card-body">
                            <h5 className="card-title p-3">{post.title.rendered}</h5>
                            <div className="p-3" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                            </div>
                            
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Category;