import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Header() {
    const [siteLogo, setSiteLogo] = useState('');

    useEffect(() => {
      const loadSiteLogo = async () => {
        const response = await axios.get('https://codehints.in/wp-json/wp/v2/settings');
        setSiteLogo(response.data.site_icon);
      };
  
      loadSiteLogo();
  }, []);

  return (
    <div>
    {siteLogo && <img src={siteLogo} alt="Site Logo" />}
  </div>
  );
}

export default Header;