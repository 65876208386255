import React from 'react';
import { BrowserRouter as Router,Route, Routes} from 'react-router-dom';
import PostDetails from './Application/PostDetails';
import HomeScreen from './Application/Home';
import CategoryList from './Application/CategoryList';
import Category from './Application/Category';

function Routing() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomeScreen />} />
        <Route path="/posts/:id" element={<PostDetails />} />
        <Route path="/cat/:id" element={<Category />} />
      </Routes>
    </Router>
  );
}

export default Routing;
