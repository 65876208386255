import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PostDetails = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      // const response = await axios.get(`https://codehints.in/wp-json/wp/v2/posts/${id}`);
      // setPost(response.data);

      axios.get(`https://codehints.in/wp-json/wp/v2/posts/${id}`).then(response => {
        setPost(response.data);
      })
    };
    fetchPost();
  }, [id]);

  return (
    <section>
      <div className='container'>
        {post && (
          <div>
            <h1>{post.title.rendered}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </div>
        )}
      </div>
    </section>
  );
};

export default PostDetails;
