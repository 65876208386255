import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

function CategoryList() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("https://codehints.in/wp-json/wp/v2/categories?per_page=100")
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      });
  }, []);

  return (
    <div>
      <h1>Categories</h1>
      <ul>
        {categories.map((category) => (
            <li key={category.id}>
                <Link to={`/cat/${category.id}`}>{category.name}</Link>
                {category.id}
            </li>
        ))}
      </ul>
    </div>
  );
}

export default CategoryList;
