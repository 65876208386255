import React, { useEffect, useState } from 'react';
import apiData from '../services/wordpressApi';
import axios from 'axios';
import CategoryList from './CategoryList';
import { Link } from 'react-router-dom';
import PopularPosts from './PopuilarPost';
import Header from './Header';
import SearchWidget from './Search';


export default function HomeScreen() {
    
    const [posts, setPosts] = useState([]);
    
    const getPostsData = async () => {
       
        try {
          await apiData
            .getPosts()
            .then((data) => {    
              setPosts(data.json);
            });
        } catch (err) {
          console.log(err);
        }
      };

    useEffect(() => {
       // getPostsData();
       axios.get('https://codehints.in/wp-json/wp/v2/posts?_embed').then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.log(error);
      });
    
       
       
      
        
   }, [])
  return (
    <div className="container">
      <Header />
      <SearchWidget />
			<div className='row mb-5'>
        <div className='col-sm-8'>
          <div className='row'>
            {posts.map((post) =>
              <div className='col-sm-4 mb-5' key={post.id}>
                <div className="card">
                {post.featured_media !== 0 && (
                      <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post._embedded['wp:featuredmedia'][0].alt_text} className="card-img-top" />
                      )}
                  <div className="card-body">
                    <h5 className="card-title">
                    <Link to={`/posts/${post.id}`}>{post.title.rendered}</Link>
                    </h5>
                    <p className="card-text">
                    {post.excerpt.rendered}
                    </p>
                    <Link to={`/posts/${post.id}`}>More</Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='col-sm-4'>
          <CategoryList />
        </div>
			</div>
      <div className='d-block'>
        {/* <PopularPosts /> */}
      </div>
	</div>
   
 );
}