import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function SearchWidget() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    const response = await axios.get(`https://codehints.in/wp-json/wp/v2/posts?search=${searchQuery}`);
    setSearchResults(response.data);
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <div className="input-group mb-3">
            <input type="text"  className="form-control"  value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
            <button type="submit" className='btn btn-primary'>Search</button>
        </div>
      </form>
      {searchResults.length > 0 && (
        <ul>
          {searchResults.map((post) => (
            <li key={post.id}>
              <h2>{post.title.rendered}</h2>
              <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
              <Link to={`/posts/${post.id}`}>More</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchWidget;
