import React from 'react';
import { BrowserRouter as Component, Route,Switch, Link, Routes } from 'react-router-dom'
import HomeScreen from './Application/Home';
import PostDetails from './Application/PostDetails';  
import Routing from './Route';

class App extends React.Component{
  constructor(){
    super();
  }
  render(){
    return(
      <>
        <Routing />
        <Component></Component>
      </>
    )
  }
}

export default App;
